import './App.css';
import React from 'react';
import {
  Box,
  Button,
  Drawer, List, ListItem, ListItemButton,
  Typography
} from '@mui/material';
import { useRuns } from './hooks';

function Runs({ username }) {
  const [state, setState] = React.useState({
    open: false,
  });
  const { data, isLoading, isError } = useRuns(state['open'] ? username : null)

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, open });
  };

  const list = () => {
    if (isError) {
      return <Typography variant="h4">wystąpił błąd Sadge</Typography>
    }

    if (isLoading) {
      return <div style={{width: 350, height: '100%', textAlign: 'center'}}>
        <img src="https://cdn.7tv.app/emote/60ae9d9a4b1ea4526dc4ee28/4x" alt="wicked" style={{width: '50%', marginTop: 20}} />
      </div>
    }

    if (data.length === 0) {
      return <div style={{width: 350, height: '100%', textAlign: 'center'}}>
        <Typography variant="h4">nie ukończył(a)</Typography>
        <img src="https://cdn.7tv.app/emote/60420e3f77137b000de9e675/4x" alt="wicked" style={{width: '50%', marginTop: 20}} />
      </div>
    }

    return <Box
      sx={{ width: 350 }}
      role="presentation"
    >
      <List>
        {data.map((run, index) => {
          const seconds = run.time/1000000000;
          const date = new Date();
          date.setHours(0,0,0,0);
          date.setSeconds(seconds);
          const formattedTime = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`
          const styles = !run.clip ? {opacity: 0.8} : {}

          return (
            <ListItem key={index}>
              <ListItemButton target="_blank" component="a" disabled={!run.clip} href={run.clip || '#'} style={styles}>
                Run {index+1} - {formattedTime}
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Box>
  };

  return (
    <>
      <Button onClick={toggleDrawer(true)} size="small" variant="outlined" style={{marginLeft: 5}}>Zobacz</Button>
      <Drawer
        anchor="right"
        open={state['open']}
        onClose={toggleDrawer(false)}
      >
        {list('right')}
      </Drawer>
    </>
  );
}

export default Runs;
