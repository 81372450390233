import './App.css';
import {
  Avatar, Button,
  Card,
  CardContent, Grid,
  Typography
} from '@mui/material';
import { getProfilePicture, getTwitchUrl } from './hooks';
import Runs from './Runs';
import React from 'react';

function Main({ summary }) {
  const userCard = ({ id: name, login, pb: time, runs, live }) => {
    const seconds = time/1000000000;
    const date = new Date();
    date.setHours(0,0,0,0);
    date.setSeconds(seconds);
    const formattedTime = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`

    const finishedSummary = summary.filter(e => e.runs > 0);
    finishedSummary.sort(function (a, b) {
      return a.pb - b.pb
    })

    const leader = finishedSummary[0];

    return (
      <>
        <CardContent>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
            <Grid item xs={3} >
              <a href={getTwitchUrl(login)} target="_blank" rel="noreferrer">
                <Avatar alt={name} src={getProfilePicture(name)} sx={{ width: "100%", height: "auto" }} style={{marginTop: 10, border: live ? 'solid red 5px' : null}} />
              </a>
            </Grid>
            <Grid item xs={9} textAlign="center">
              <div style={{paddingLeft: 15, paddingTop: 25, position: 'relative'}}>
                <Typography variant="h5" component="div">
                  {name} {runs > 0 ? <Runs username={name}/> : null}
                </Typography>
                {runs ? (
                  <>
                  {leader.id === name ? (<img src="https://cdn.7tv.app/emote/60b3e71ba8c161674635ba23/4x" alt="PogU" style={{width: 80, transform: 'scaleX(-1)', position: 'absolute', right: -33, bottom: -30}} />) : null}
                    <Typography marginTop={2} variant="body2">
                      PB: <b>{formattedTime}</b> w <b>{runs} {runs === 1 ? 'runie' : 'runach'}</b>
                    </Typography>
                  </>
                ) : (
                  <>
                    <img src="https://cdn.7tv.app/emote/610bde3d637fa95aba96413e/4x" alt="PepeLaugh" style={{width: 80, transform: 'scaleX(-1)', position: 'absolute', right: -15, bottom: -35}} />
                    <Typography marginTop={2} variant="body2">
                      nie ukończył(a)
                    </Typography>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  }

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} marginTop={5}>
        {summary.map(user => {
          return (<Grid key={user.id} item xs={6}>
            <Card>{userCard(user)}</Card>
          </Grid>);
        })}     
      </Grid>
      </>
  );
}

export default Main;
