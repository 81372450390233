import './App.css';
import {
  Card,
  CardContent,
  Grid, Popover,
  Typography, Button
} from '@mui/material';
import React, { useEffect, useState } from 'react';

function Sidebar({ summary }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const card = (title, subtitle) => (
    <>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div">
          {subtitle}
        </Typography>
      </CardContent>
    </>
  );

  const finishedSummary = summary.filter(e => e.runs > 0);
  finishedSummary.sort(function (a, b) {
    return a.pb - b.pb
  })

  const leader = finishedSummary[0];

  const [now, setNow] = useState(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date().getTime()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const countDownDate = new Date("Jan 31, 2022 23:59:59").getTime();
  // const days = Math.floor((countDownDate - now) / (1000 * 60 * 60 * 24));
  // const hours = Math.max(Math.floor((countDownDate - now) / (1000 * 60 * 60)), 0);
  const minutes = Math.max(Math.ceil((countDownDate - now) / (1000 * 60)), 0);

  let timeLeftText = `${minutes} minut${minutes === 1 ? 'a' : ''}`;
  let leaderText = 'Aktualny lider';
  if (minutes <= 0) {
    timeLeftText = 'koniec!';
    leaderText = 'Zwycięzca';
  }

//     <Grid item xs={12}>
//       <Card variant="outlined">{card("Nagroda", `${(summary.length-1)*20} subów`)}</Card>
//     </Grid>
//     <Grid item xs={12}>
//       <Card variant="outlined">{card(leaderText, leader.id)}</Card>
//     </Grid>
// <Grid item xs={12}>
//       <Card variant="outlined">{card("Do końca pozostało", timeLeftText)}</Card>
//     </Grid>

  return (
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }} marginTop={5}>
    <Grid item xs={12}>
      <div style={{textAlign: 'center'}}>
        <img src="https://cdn.7tv.app/emote/60ae9d9a4b1ea4526dc4ee28/4x" alt="wicked" style={{width: '100%', borderRadius: 5}} />
      </div>
    </Grid>
    <Grid item xs={12}>
      <Card variant="outlined">{card("Runa ukończyło", `${finishedSummary.length} z ${summary.length} uczestników`)}</Card>
    </Grid>
    <Grid item xs={12}>
      <Button aria-describedby={id} variant="outlined" size="small" color="primary" onClick={handleClick} fullWidth>
      zgłoś nowy rekord
    </Button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Typography sx={{ p: 2 }}>Nowy czas? Zgłoś na Discord do adiq#7777</Typography>
    </Popover>
    </Grid>
  </Grid>
  );
}

export default Sidebar;
