import useSWR from 'swr';
import { fetcher } from './fetcher';

export function useSummary () {
  const { data, error } = useSWR(`https://mc-api.adamcy.pl/summary`, fetcher, { refreshInterval: 60000 })

  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useRuns (username) {
  const { data, error } = useSWR(username ? `https://mc-api.adamcy.pl/runs/${username}` : null, fetcher, { refreshInterval: 60000 })

  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}

export function getProfilePicture(username) {
  switch (username.toLowerCase()) {
    case 'slayproxx':
      return 'https://cdn.7tv.app/pp/611a5ebe72c501b4a93be7ec/16b521edd9b04ca1aeb2deaea86a9b35';
    case 'karigoda':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/638ef03a-370b-4686-a62a-3b8cdd7dc8a2-profile_image-300x300.png';
    case 'remsua':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/ca461602-e175-4319-bb4f-cc44e3a15ff5-profile_image-300x300.png';
    case 'xemtek':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/e1f55d19-3327-402d-a2ab-fafec4bcecc4-profile_image-300x300.png';
    case 'sarko':
      return 'https://cdn.7tv.app/pp/60b254b68fbc2479a2c3f3e1/555f5969d7c24e8898ee49d32bcefd68';
    case 'diabeuu':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/diabeuu6-profile_image-e52768434729719a-300x300.jpeg';
    case 'ortis':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/310ee65d-6302-4132-9aba-1c2ee7d85e49-profile_image-300x300.png';
    case 'flothar':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/65b12dcf-52c2-44c0-95b3-67054b76da60-profile_image-300x300.png';
    case 'nieuczesana':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/d4fed3f2-0c8e-4138-a3da-6b471bc430c8-profile_image-300x300.png';
    case 'patiro':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/3738fe71-42e6-4475-9c6b-1b2d63e45748-profile_image-300x300.jpg';
    case 'bytake':
      return 'https://static-cdn.jtvnw.net/jtv_user_pictures/bytake-profile_image-ea789d884678434c-70x70.jpeg';
    default:
      return 'https://cdn.7tv.app/emote/60ae9d9a4b1ea4526dc4ee28/4x'
  }
}

export function getTwitchUrl(login) {
  return `https://www.twitch.tv/${login}`
}