import './App.css';
import { Box, Container, createTheme, CssBaseline, Grid, ThemeProvider } from '@mui/material'
import Main from './Main';
import Sidebar from './Sidebar';
import { useSummary } from './hooks';

export const themeOptions = {
  palette: {
    mode: 'dark',
    type: 'dark',
    primary: {
      main: '#eae2e2',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#2b2b2b',
      paper: '#252525',
    },
  },
};
const theme = createTheme(themeOptions);


function App() {
  const { data, isLoading, isError } = useSummary()

  let app = <Box
    marginTop={2}
    marginBottom={2}
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: 1,
      gridTemplateRows: 'auto',
      gridTemplateAreas: `"sidebar main main main"
      "footer footer footer footer"`,
    }}
    style={{minWidth: 1000}}
  >
    <Box sx={{ gridArea: 'main' }}>
      <Main summary={data} />
    </Box>
    <Box sx={{ gridArea: 'sidebar' }}>
      <Sidebar summary={data} />
    </Box>
    {/*<Box sx={{ gridArea: 'footer'}}>*/}
    {/*  <Footer/>*/}
    {/*</Box>*/}
  </Box>

  if (isLoading) {
    app = <Grid item xs={12}>
      <div style={{textAlign: 'center'}}>
        <img src="https://cdn.7tv.app/emote/60ae9d9a4b1ea4526dc4ee28/4x" alt="wicked" style={{marginTop: '15%'}} />
      </div>
    </Grid>
  }

  if (isError) {
    app = <Grid item xs={12}>
      <div style={{textAlign: 'center'}}>
        <h2>coś się zepsuło...</h2>
        <img src="https://cdn.7tv.app/emote/603caf09c20d020014423c14/4x" alt="trisad" style={{marginTop: '15%'}} />
        <h2>zaraz naprawimy</h2>
      </div>
    </Grid>
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" >
        <CssBaseline />
        {app}
      </Container>
    </ThemeProvider>
  );
}

export default App;
